import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { NewsItem } from '~components/NewsItem'
import Icon from '~components/Icon'
import building from './img/building.svg'
import cn from 'classnames'
import {
  news,
  news_item,
  first,
  news_content,
  news_title,
  news_descr,
  news_footnote,
  news_link,
} from './style.module.scss'

export const Newslist = () => {
  const data = useStaticQuery(graphql`
    {
      allNewsJson {
        edges {
          node {
            id
            date
            brief
            title
            slug
          }
        }
      }
    }
  `)

  return (
    <section className={news}>
      {data.allNewsJson.edges.map(({ node: { title, brief, date, slug, id } }, i) =>
        i === 0 ? (
          <div key={id} className={cn(news_item, first)}>
            <div>
              <img src={building} alt="Building" />
            </div>
            <div className={news_content}>
              <p className={cn(news_title, first)}>{title}</p>
              <p className={cn(news_descr, first)}>{brief}</p>
              <div className={cn(news_footnote, first)}>
                {date}{' '}
                <Link to={slug} className={cn(news_link, first)}>
                  Read more
                  <Icon name="arrow-right" size={16} />
                </Link>
              </div>
            </div>
          </div>
        ) : (
          <NewsItem key={id} slug={slug} title={title} brief={brief} date={date} />
        )
      )}
    </section>
  )
}
