import * as React from 'react'
import { Container } from 'react-bootstrap'
import Layout from '~components/Layout'
import SEO from '~components/seo'
import { Hero } from './components/Hero'
import { Newslist } from './components/Newslist'

export const News = () => {
  return (
    <Layout>
      <Container>
        <SEO title="Newsletter" />
        <Hero />
        <Newslist />
      </Container>
    </Layout>
  )
}
